.search {

	.search-result {
		@include grid-col(12,0);

		padding-bottom: rem-calc(15);
		margin-bottom: rem-calc(30);
		border-bottom: 1px solid #ededed;

		&:last-of-type {
			border-bottom: none;
		}

		h3 {
			color: map-get($foundation-palette, primary);
		}
	}
}