.fast-offer {
	background-color: #fafafa;
	border-top: 1px solid #ededed;
	border-bottom: 1px solid #ededed;

	a {
		&[data-toggle] {
			display: block;
			padding: rem-calc(15 10);
			position: relative;

			@include breakpoint(large){
				display: none;
			}

			&:after {
				@include css-triangle(5px, map-get($foundation-palette, primary), down);
				position: absolute;
				top: 50%;
				right: rem-calc(15);
			}
		}
	}

	.menu {
		text-align: center;

		@include breakpoint(medium down){
			display: none;

			&.show {
				display: block;
			}
		}

		@include breakpoint(large){
			display: flex;
			flex-wrap: nowrap;
			justify-content: space-between;
		}



		li {
			@include breakpoint(small){
				display: block;
			}
			@include breakpoint(large){
				display: inline-block;
				border-right: 1px solid #ededed;
				flex-grow:1;
			}
			

			&:last-child {
				border-right:none;
			}
		}

		a {
			font-size: rem-calc(14);
			padding: rem-calc(17);
			color: map-get($foundation-palette, primary);
			text-transform: uppercase;

			&:hover {
				color: white;
				background-color: map-get($foundation-palette, primary);
			}
		}
	}
}