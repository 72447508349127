footer#footer {
	background-color: map-get($foundation-palette, primary);
	color: white;
	padding: rem-calc(15 0 20);
	font-size: rem-calc(14);

	.copyright {
		@include grid-col;
		@include breakpoint(medium down){
			text-align: center;
		}
		@include breakpoint(large){
			@include grid-col-size(5);
			line-height: rem-calc(36);
		}
	}

	.soboldesign {
		@include grid-col;
		text-align: center;

		@include breakpoint(medium down){
			margin: rem-calc(15 0);
		}

		@include breakpoint(large){
			@include grid-col-size(2);
		}
	}

	.nav {
		@include grid-col;
		@include breakpoint(medium down){
			text-align: center;
		}
		@include breakpoint(large){
			@include grid-col-size(5);
		}

		.menu {
			@include breakpoint(medium down){
				text-align: center;
			}
			@include breakpoint(large){
				text-align: right;
			}

			li {
				display: inline-block;

				&:last-child {
					a {
						&:after {
							content: none;
						}
					}
				}
			}
		}		

		a {
			color: inherit;
			position: relative;

			&:hover {
				text-decoration: underline;
			}

			&:after {
				content: "";
				width: 1px;
				height: 14px;
				background-color: white;
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
	}
}