.card {
	margin-bottom: rem-calc(40);
	.image {
		display: block;
		
		background-repeat: no-repeat;
		background-position: 50% 50%;
		background-size: cover;
		@include breakpoint(small) {
			min-height: rem-calc(130);
		}
		@include breakpoint(medium) {
			min-height: rem-calc(140);
		}
		@include breakpoint(large) {
			min-height: rem-calc(195);
		}

		a {
			display: block;
			width: 100%;
			text-indent: -9999px;
			@include breakpoint(small) {
				min-height: rem-calc(130);
			}
			@include breakpoint(medium) {
				min-height: rem-calc(140);
			}
			@include breakpoint(large) {
				min-height: rem-calc(195);
			}
		}
	}

	.content {
		@include breakpoint(small) {
			padding: rem-calc(15);
		}
		@include breakpoint(large) {
			padding: rem-calc(35 28);
		}
		border: 1px solid #ededed;

		h1,h2,h3,h4,h5,h6 {
			color: map-get($foundation-palette, primary);
			font-weight: bold;

			small {
				font-size: rem-calc(14);
				font-weight: normal;
			}

			a {
				color: inherit;
				&:hover{
					text-decoration: none;
				}
			}
		}

		a {
			color: map-get($foundation-palette, alert);

			&:hover {
				text-decoration: underline;
			}
		}

		small {
			position: relative;
			display: inline-block;
			top: rem-calc(-10);
			margin-bottom: rem-calc(10);
		}

		.primary {
			color: map-get($foundation-palette, primary);
		}

		&.intro {
			@include breakpoint(medium){
				padding-left: rem-calc(30) !important;
				p {
					font-size: rem-calc(24);
					line-height: 1.3;
				}
			}
			
		}
	}

	&.no-border {
			.content {
				border:none;
				padding: rem-calc(15);
			}
		}

	&.left-image {
		@include grid-row;
		.image {
			@include grid-col;
			@include breakpoint(medium){
				@include grid-col-size(6);
			}
			@include breakpoint(large){
				@include grid-col-size(4);
			}
		}
		.content {
			@include grid-col;
			@include breakpoint(medium){
				@include grid-col-size(6);
				padding-left: rem-calc(30);
			}
			@include breakpoint(large){
				@include grid-col-size(8);
			}
		}

		&.large {
			.image {
				@include breakpoint(medium){
					@include grid-col-size(7);
				}
				@include breakpoint(large){
					@include grid-col-size(8);
				}
			}
			.content {
				@include breakpoint(medium){
					@include grid-col-size(5);
				}
				@include breakpoint(large){
					@include grid-col-size(4);
				}
			}
		}
		&.half {
			.image {
				@include breakpoint(medium){
					@include grid-col-size(6);
				}
			}
			.content {
				@include breakpoint(medium){
					@include grid-col-size(6);
					padding-left: rem-calc(30);
				}
			}
		}
	}

	&.right-image {
		@include grid-row;
		.image {
			@include grid-col;
			@include breakpoint(medium){
				@include grid-col-size(6);
				@include grid-col-pos(6);
			}
			@include breakpoint(large){
				@include grid-col-size(4);
				@include grid-col-pos(8);
			}
		}
		.content {
			@include grid-col;
			@include breakpoint(medium){
				@include grid-col-size(6);
				@include grid-col-pos(-6);
				padding-right: rem-calc(30);
			}
			@include breakpoint(large){
				@include grid-col-size(8);
				@include grid-col-pos(-4);
			}
		}

		&.large {
			.image {
				@include breakpoint(medium){
					@include grid-col-size(7);
					@include grid-col-pos(5);
				}
				@include breakpoint(large){
					@include grid-col-size(8);
					@include grid-col-pos(4);
				}
			}
			.content {
				@include breakpoint(medium){
					@include grid-col-size(5);
					@include grid-col-pos(-7);
				}
				@include breakpoint(large){
					@include grid-col-size(4);
					@include grid-col-pos(-8);
				}
			}
		}

		&.half {
			.image {
				@include breakpoint(medium){
					@include grid-col-size(6);
					@include grid-col-pos(6);
				}
			}
			.content {
				@include breakpoint(medium){
					@include grid-col-size(6);
					@include grid-col-pos(-6);
					padding-right: rem-calc(30);
				}
			}
		}
	}

	/* &.large {
		@include breakpoint(medium){
			.image {
				@include grid-col-size(7);
			}
			.content {
				@include grid-col-size(5);
			}
		}
		@include breakpoint(large){
			.image {
				@include grid-col-size(8);
			}
			.content {
				@include grid-col-size(4);
			}
		}
	} */
}