.offer {
	main {
		@include grid-col;
		@include breakpoint(medium){
			@include grid-col-size(8);
		}

		.image {
			margin-bottom: rem-calc(30);
		}

		.intro {
			font-style: italic;
			font-size: rem-calc(24);
			line-height: 1.3;
			margin-bottom: rem-calc(30);
		}

		p {
			span {
				color: map-get($foundation-palette, alert);
			}
		}

		ul {
			list-style: none;
			margin: rem-calc(0 0 30 0);

			li {
				@include breakpoint(large){
					display: inline-block;
					width: 45%;
					margin-right: 4%;
				}
				color: map-get($foundation-palette, primary);
				margin-bottom: rem-calc(10);
				font-size: rem-calc(14);

				&:before {
					content:"\\";
					color: map-get($foundation-palette, alert);
					margin-right: rem-calc(10);
				}

				a {
					color: inherit;
				}
			}
		}
	}

	aside {
		@include grid-col;
		@include breakpoint(medium){
			@include grid-col-size(4);
		}

		.offer-item {
			border-bottom: 1px solid #ededed;
			padding-bottom: rem-calc(30);
			margin-bottom: rem-calc(30);

			&:last-of-type {
				border:none;
			}

			h3 {
				font-size: rem-calc(16);
				font-weight: bold;
				color: map-get($foundation-palette, primary);

				a {
					color: inherit;
				}
			}
			a {
				font-size: rem-calc(12);
				text-transform: uppercase;
			}
		}
	}
}