.main-carousel {
	.slick-slide {
		background-size: cover;
		background-position: 50% 0;
		background-repeat: no-repeat;

		@include breakpoint(small) {
			min-height: 250px;
		}
		@include breakpoint(medium) {
			min-height: 350px;
		}
		@include breakpoint(large) {
			min-height: 620px;
		}

		main {
			@include breakpoint(small) {
				margin-top: 5%;
			}
			@include breakpoint(medium) {
				margin-top: 8%;
			}
			@include breakpoint(large) {
				margin-top: 10%;
			}

			&.left {
				text-align: left;
			}
			&.right {
				text-align: right;
			}
			&.center {
				text-align: center;
			}

			header {
				display: inline-block;
				background-color: white;
				padding: rem-calc(10 20);
				margin: rem-calc(0 0 10 0);
				color: map-get($foundation-palette, primary);

				@include breakpoint(large) {
					position: relative;

					&:after {
						content: "";
						position: absolute;
						top: 0;
						right: 0;
						width: 20px;
						height: 100%;
						background-color:white;
						transform-origin: 100% 0;
						transform: skew(20deg);
					}
				}
			}
			p {
				color: white;
				font-weight: bold;

				@include breakpoint(small) {
					
				}
				@include breakpoint(medium) {
					font-size: rem-calc(22);
					line-height: rem-calc(26);
				}
				@include breakpoint(large) {
					font-size: rem-calc(34);
					line-height: rem-calc(40);
				}
			}
			
			a {
				color: inherit;
				outline: none;
			}
		}
	}

	.slick-dots {
		margin: rem-calc(5 0);
		list-style: none;
		text-align: center;

		@include breakpoint(large) {
			margin-top: -35px;
			position: relative;
			z-index: 10;
		}

		li {
			display: inline-block;
			margin: rem-calc(0 3);

			&.slick-active {
				button {
					background-color: map-get($foundation-palette, primary);
				}
			}

			button {
				width: 15px;
				height: 15px;
				text-indent: -9999px;
				background-color: rgba(white,0.5);
				border-radius:50%;
				border: none;
				margin: 0;
				padding: 0;
			}
		}
	}
}