.contact {
	
	.map {
		@include grid-col;
		@include breakpoint(medium down) {
			margin-bottom: rem-calc(20);
		}
		@include breakpoint(large) {
			@include grid-col-size(6);
		}
	}

	#map {min-height: rem-calc(410);}

	.details {
		@include grid-col;
		@include breakpoint(large) {
			@include grid-col-size(6);
		}

		.image {
			min-height: rem-calc(410);
		}
	}
}