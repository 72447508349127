header {

	&.primary {
		position: relative;

		&:after {
			content: "";
			display: inline-block;
			width: rem-calc(55);
			height: rem-calc(1);
			background-color: map-get($foundation-palette, alert);
			position: absolute;
			bottom: -15px;
		}

		&.left {
			text-align: left;
			&:after {
				left: 0;
			}
		}
		&.right {
			text-align: right;
			&:after {
				right: 0;
			}
		}
		&.center {
			text-align: center;
			&:after {
				left: 50%;
				transform: translateX(-50%);
			}
		}

		span {
			text-transform: uppercase;
			font-size: rem-calc(16);
			color: #b8b8b8;
		}
		.head {
			color: map-get($foundation-palette, primary);
			font-weight: bold;
			margin: rem-calc(0 0 45);
			padding: 0;

			@include breakpoint(small){
				font-size: rem-calc(28);
			}
			@include breakpoint(medium){
				font-size: rem-calc(36);
			}

			&.uppercase {
				text-transform: uppercase;
			}
		}

		.back {
			position: absolute;
			bottom: 0;
			right: 0;
			color: #b8b8b8;

			&:after {
				content: "";
				width: 39px;
				height: 46px;
				display: inline-block;
				background:url('../img/layout/return.png') 0 0 no-repeat;
				vertical-align: text-top;
			    margin-top: 4px;
			}

			&:hover {
				color: map-get($foundation-palette, alert);
			}
		}
	}

	.header & {
		.head {
			margin: rem-calc(0 0 15);

			small {
				font-size: rem-calc(18);
				font-weight: normal;
			}
		}
	}
}

.header {
	background-color: #fafafa;
	padding-top: rem-calc(30);
	margin-bottom: rem-calc(30);
}