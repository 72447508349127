.home {
	.offer-head {
		margin-top: rem-calc(35);

		.offer {
			@include breakpoint(small) {
				@include grid-col(12);
			}
			@include breakpoint(medium) {
				@include grid-col(6,0);
			}
		}
		.phone {
			@include breakpoint(small) {
				@include grid-col(12);
			}
			@include breakpoint(medium) {
				@include grid-col(6,0);
			}
		}
	}

	.offer {
		@include breakpoint(small) {
			@include grid-layout(1);
		}
		@include breakpoint(medium) {
			@include grid-layout(3);
		}
	}

	.blog {
		@include breakpoint(small) {
			@include grid-layout(1);
		}
		@include breakpoint(medium) {
			@include grid-layout(3);
		}
	}

	.why-us {
		margin-top: rem-calc(40);
		margin-bottom: rem-calc(50);

		.intro {
			@include breakpoint(small) {
				@include grid-column(12);
			}
			@include breakpoint(medium) {
				@include grid-column(6,0);
			}
			@include breakpoint(large) {
				@include grid-column(4,0);
			}
		}
		.strengths {
			@include breakpoint(small) {
				@include grid-column(12,0);
			}
			@include breakpoint(medium) {
				@include grid-column(6,0);
			}
			@include breakpoint(large) {
				@include grid-column(8,0);
				@include grid-layout(2);
			}

			.media-object {
				border: $bar-border;
				@include breakpoint(small) {
					padding: rem-calc(10);
				}
				@include breakpoint(large) {
					padding: rem-calc(25);
				}

				h3 {
					color: map-get($foundation-palette, primary);
					text-transform: uppercase;
					font-weight: bold;
				}
				p {
					margin: 0;
					font-size: rem-calc(14);
					line-height: rem-calc(19);
				}
			}
		}
	}

	.offer {
		.slick-slide {
			outline: none;
		}
	}

	.about {
		@include breakpoint(small) {
			background-color: map-get($foundation-palette, primary);
			margin-bottom: rem-calc(40);

			header {
				h2 {
					color: white;
				}
			}

			h3 {
				font-weight: bold;
			}
		}
		@include breakpoint(medium) {
			background: repeating-linear-gradient(
					map-get($foundation-palette, primary) 0, 
					map-get($foundation-palette, primary) 65%, 
					#ededed 65%, 
					#ededed 100%);

			h3,
			.color {
				color: map-get($foundation-palette, primary);
			}

			blockquote {
				margin-bottom: rem-calc(40);
				position: relative;

				&:after {
					@include css-triangle(30px, map-get($foundation-palette, alert), up);
					position: absolute;
					bottom: -15px;
					left: -15px;
					transform: rotate(90deg);
				}
			}
		}

		.image {
			@include breakpoint(medium) {
				@include grid-col(6,0);
				min-height: 500px;
				background-size: cover;
				background-repeat:no-repeat;
				background-position: top center;
			}
		}

		.column {
			padding-top: rem-calc(20);
			padding-bottom: rem-calc(20);
			@include breakpoint(medium) {
				@include grid-col(6,80);
			}
		}
	}
}