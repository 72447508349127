$bar-height: rem-calc(46);
$bar-border: 1px solid #ededed;

.page-top-bar {
	background-color: #fafafa;
	border-bottom: $bar-border;
	height: $bar-height;

	@include breakpoint(small) {
		padding: rem-calc(0 8);
	}

	@include breakpoint(medium down) {
		display: none;
	}
	.top-bar-email {
		@include breakpoint(large) {
			@include grid-col(3,0);
		}
	}

	.top-bar-language {
		@include breakpoint(large){
			@include grid-col(2,0);
			@include grid-col-off(1);
		}
	}

	.top-bar-search {
		@include breakpoint(large){
			@include grid-col(3,0);
		}
	}

	.top-bar-experts {
		@include breakpoint(large){
			@include grid-col(3,0);
		}
	}
}

.top-bar-email {
	a {
		display: inline-block;
		color: inherit;
		font-size: rem-calc(14);
		line-height: $bar-height;
		border-left: $bar-border;
		border-right: $bar-border;
		padding: rem-calc(0 20);

		&:hover {
			color: map-get($foundation-palette, alert);
		}

		&:before {
			content:"";
			display: inline-block;
			width: 20px;
			height: 21px;
			background: url('../img/layout/top-bar-email.png') 0 40% no-repeat;
			margin-right: 10px;
			height: $bar-height;
			vertical-align: middle;
		}
	}
}

.top-bar-language {
	text-align: right;

	> button {
		font-size: rem-calc(14);
		padding: rem-calc(0 20);
		border-left: $bar-border;

		&:before {
			content:"";
			width: 54px;
			height: 29px;
			display: inline-block;
			background: url('../img/layout/map-lang.png') 0 50% no-repeat;
			margin-right: 10px;
			height: $bar-height;
			vertical-align: middle;
		}

		&:after {
			@include css-triangle(5px, #bebebe, down);
			display: inline-block;
			margin-left: 5px;
		}
	}

	.dropdown-pane {
		width: rem-calc(150);
		padding: 0;

		ul {
			margin: 0;
			list-style: none;
			text-align: center;

			li {
				a {
					color: inherit;
					display: block;
					padding: rem-calc(5 0);
					&:hover {
						background-color: map-get($foundation-palette, primary);
						color: white;
					}
				}
			}
		}
	}
}

.top-bar-search {

	form {
		position: relative;
	}
	input {

		&[type="text"] {
			box-shadow:none;
			font-size: rem-calc(12);
			height: $bar-height;
			margin:0;
			padding: rem-calc(8 $bar-height 8 8);
			border: $bar-border; 
		}

		&[type="submit"] {
			width: $bar-height;
			height: $bar-height;
			background-color: #bebebe;
			background-image: url('../img/layout/magnifier.png');
			background-position: 50% 50%;
			background-repeat: no-repeat;
			border: none;
			position: absolute;
			top: 0;
			right: 0;
		}
	}
}

.top-bar-experts {

	height: $bar-height;
	line-height: $bar-height;
	background-color: map-get($foundation-palette, alert);
	color: white;
	text-transform: uppercase;
	text-align: center;
	padding: rem-calc(0 25 0 0);
	position: relative;
	overflow: hidden;

	span {
		display: block;
		width: 20px;
		height: 60px;
		background-color: #fafafa;
		position: absolute;
		top: 0;
		bottom:0;
		right: -12px;
		transform: rotate(15deg);
	}

	a {
		display: block;
		color: inherit;
	}
}