.top-bar {
	@include breakpoint(medium down) {
		padding: rem-calc(10 8);
		border-bottom: $bar-border;
	}

	h1 {
		margin:0;
		padding-right:rem-calc(45);
	}

	.top-bar-title {
		span {
			position: absolute;
			right: rem-calc(20);
			@include breakpoint(small) {
				top: rem-calc(15);
			}
			@include breakpoint(medium) {
				top: rem-calc(20);
			}
		}
	}

	.top-bar-right,
	.top-bar-left {
		@include breakpoint(medium down){
			clear: both;
			float: none;
		}
	}
	.top-bar-left {
		@include breakpoint(large){
			display: none;
		}
	}

	#main-menu {
		.menu {
			@include breakpoint(medium down){
				@include menu-direction(vertical);
				padding: rem-calc(20 0);
			}
			@include breakpoint(large){
				@include menu-direction(horizontal);

				font-size: rem-calc(14);
				margin-top: rem-calc(8);

				li {
					position: relative;

					&:after {
						content: "";
						display: inline-block;
						width: 1px;
						height: 14px;
						background-color: $body-font-color;
						position: absolute;
						top: 50%;
						right: 0;
						transform: translateY(-50%);
					}

					&:last-child {
						&:after {
							content: none;
						}
					}
				}
			}

			li {
				&.active {
					a {
						background-color: transparent;
						color: map-get($foundation-palette, alert);
					}
				}
			}

			a {
				color: inherit;

				&:hover {
					color: map-get($foundation-palette, alert);
				}
			}
		}
	}

	.top-bar-language {
		border: $bar-border;
		margin-bottom: rem-calc(10);
		> button {
			width: 100%;
			border-left:none;
			text-align: left;
			position: relative;

			&:after {
				position: absolute;
				right: rem-calc(15);
				top: 50%;
			}
		}
		.dropdown-pane {
			width: 100%;
			left: 15px;
			right:15px;
			text-align: left;
		}
	}

	.top-bar-search {
		input {
			&[type=text] {
				max-width: none;
			}
			&[type=submit] {
				margin:0;
			}
		}
	}
}



.no-js {
	@include breakpoint(medium down) {
		.top-bar {
			display: none;
		}
	}
	@include breakpoint(large) {
		.title-bar {
			display: none;
		}
	}
}