a {
	color: map-get($foundation-palette, alert);
	transition: 200ms;

	&.more {
		&:after {
			content:"\226B";
			margin-left: rem-calc(5);
		}
	}
}

blockquote {
	background-color:map-get($foundation-palette, alert);
	font-style: italic;
}